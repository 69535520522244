<template>
  <v-select
    label='Student'
    class="white"
    :items="data"
    item-text="name"
    item-value="id"
    v-model="id"
    :loading="api.isLoading"
    placeholder="Student"
  ></v-select>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError", "classroom"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.data, { id: this.id });
      this.callbackSelect(this.item);
    },
  },
  methods: {
   fetch() {
       this.api.url =`${this.$api.servers.sso}/api/v1/en/data/classroom/${this.classroom.id}/student`
      this.$api.fetch(this.api);
    }
  },
};
</script>
