<template>
  <v-select
    label='Reason'
    class="white"
    :items="data"
    v-model="item"
    placeholder="Reason"
  ></v-select>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError", "classroom"],
  data: () => ({
    data: ["Under B40", "Under Rancangan Makanan Tambahan",  "Under Lion's Club","Other Reason"],
    item: null,
  }),
  created() {
    //BOC:[api]
  },
  watch: {
    item: function (val) {
      this.item = val;
      this.callbackSelect(this.item);
    },
  },
  methods: {
   
  },
  mounted(){
    this.item = this.data[0]
  }
};
</script>
