<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Sponsored Student</v-card-title>
        <v-card-text>
          <template>
            <Institution
              :callbackSelect="selectInstitution"
              @input="form['institutionId'] = $event"
            />
          </template>
          <template>
            <Classroom
              ref="classroom"
              :institution="institution"
             :callbackSelect="selectClassroom"
              :callbackError="showError"
              @input="form['classroomId'] = $event"
            />
          </template>
          <template>
            <Student
              ref="student"
              :classroom="classroom"
             :callbackSelect="selectStudent"
              :callbackError="showError"
              @input="form['studentId'] = $event"
            />
          </template>
          <template>
            <Reason
             :callbackSelect="selectReason"
              :callbackError="showError"
              @input="form['appliedReason '] = $event"
            />
          </template>
          <template>
            <TextField
                v-if="reason == 'Other Reason'"
                :value="other"
                label="Other Reason"
                :rules="nameRules"
                type="text"
                @input="other = $event"
              ></TextField>
          </template>
          <template>
            <Sponsorship
             :callbackSelect="selectSponsorship"
              :callbackError="showError"
              @input="form['sponsroshipId'] = $event"
            />
          </template>
          <template>
            <Date
              :callbackSelect="selectDate"
              formKey="dateStart"
              name="Start Date"
              :value="form.dateStart"
            />
          </template>
          <!-- <template>
            <Time
              :callbackSelect="selectTime"
              formKey="timeStart"
              name="Start Time"
              :value="form.timeStart"
            />
          </template> -->
          <template>
            <Date
              :callbackSelect="selectDate"
              formKey="dateEnd"
              name="End Date"
              :value="form.dateEnd"
            />
          </template>
          <!-- <template>
            <Time
              :callbackSelect="selectTime"
              formKey="timeEnd"
              name="End Time"
              :value="form.timeEnd"
            />
          </template> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="submit">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import Institution from "@/components/Moderator/SponsoredStudent/Institution";
import Date from "@/components/Moderator/SponsoredStudent/Date";
// import Time from "@/components/Moderator/SponsoredStudent/Time";
import Classroom from "@/components/Moderator/SponsoredStudent/Classroom";
import Student from "@/components/Moderator/SponsoredStudent/Student";
import Sponsorship from "@/components/Moderator/SponsoredStudent/Sponsorship";
import Reason from "@/components/Moderator/SponsoredStudent/Reason";
import TextField from "@/components/Moderator/SponsoredStudent/TextField";

import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
    Institution,
    Classroom,
    Student,
    Sponsorship,
    Reason,
    TextField,
    Date,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    institution:{},
    classroom:{},
    nameRules:[],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    other:null,
    //BOC:[form]
    form: {
      institutionId: null,
      classroomId: null,
      studentId: null,
      sponsorshipId: null,
      appliedReason :null,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(1, "year").format("YYYY-MM-DD"),
      timestampStart: moment().toISOString(),
      timestampEnd: moment().toISOString(),
      timeStart: "07:00",
      timeEnd: "23:00",
    },
    reason: null,
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectInstitution(item) {
      this.institution = item;
      this.form.institutionId = item.id
      var that = this;
      setTimeout(() => {
        that.$refs["classroom"].fetch();
      }, 100);
    },
    selectClassroom(item) {
      this.classroom = item;
      this.form.classroomId = item.id
      var that = this;
      setTimeout(() => {
        that.$refs["student"].fetch();
      }, 100);
    },
    selectStudent(item) {
      this.form.studentId = item.id
    },
    selectReason(item) {
      this.reason = item
    },
    selectSponsorship(item) {
      if(item.id !=0){
      this.form.dateStart = moment(item.timestampStart).format("YYYY-MM-DD")
      this.form.dateEnd = moment(item.timestampEnd).format("YYYY-MM-DD")
      this.form.timeStart = moment(item.timestampStart).format("HH:mm")
      this.form.timeEnd = moment(item.timestampEnd).format("HH:mm")
      }else{
        this.form.dateStart = moment().format("YYYY-MM-DD")
      this.form.dateEnd = moment(item.timestampEnd).add(1, "year").format("YYYY-MM-DD")
      this.form.timeStart = "12:00"
      this.form.timeEnd = "23:59"
      }
      this.form.sponsorshipId = item.id
    },
    //BOC:[form]
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    submit() {
      this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The news start date and time should be less than the news end date and time."
          );
        } else {
      if(this.reason == 'Other Reason'){
        this.form.appliedReason  = `Other - ${this.other}`
      }else{
         this.form.appliedReason  = this.other
      }
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    }
    },
    //EOC
  },
};
</script>

<style>
</style>