<template>
  <v-text-field
    class="input-group--focused"
    v-model="inputVal"
    :type="type"
    :label="label"
    :rules="rules"
    :value="value ? value : ''"
    :prefix="prefix"
  ></v-text-field>
</template>

<script>
export default {
  props: ["label", "value","rules","type","prefix"],
  components: {
    //
  },
  computed:{
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    password: null,
    show: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>