<template>
  <v-select
    label='Institution'
    class="white"
    :items="data"
    item-text="name"
    item-value="id"
    v-model="id"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.url =`${this.$api.servers.sso}/api/v1/en/data/institution`
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    //  this.countryCode = resp[0].countryCode
      console.log(resp);
    };
    //EOC
  },
   computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
  },
  watch: {
    //BOC:[parent]
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.data, { id: this.id});
      this.callbackSelect(this.item);
    },
    //EOC
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>
